import {
  Alert,
  AlertColor,
  AlertPropsColorOverrides,
  AlertTitle,
  Card,
  Container,
  Stack,
  TableContainer,
} from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import { configureOpenAPI } from "../utils/openApi";
import {
  OpenAPI as telematorOpenAPI,
  getCircuitById,
} from "../gen/telematorClient";
import {
  OpenAPI as cfsInventoryOpenAPI,
  EquipmentsService,
} from "../gen/cfsInventoryClient";
import { OpenAPI as PainOpenAPI, getIdentify } from "../gen/painClient";
import {
  OpenAPI as ipamOpenAPI,
  getAddressspaces,
} from "../gen/ipamClient-1.2.1";
import {
  OpenAPI as bandwidthOpenAPI,
  getVersion,
} from "../gen/bandwidthClient";
import { telematorConfig } from "../authConfig";

export function Status() {
  // Number of services in each state
  const [operational, setOperational] = React.useState<number>(0);
  const [unhealthy, setUnhealthy] = React.useState<number>(0);
  const [unknown, setUnknown] = React.useState<number>(0);

  // State for each service
  const [dhcpService, setDhcpService] =
    React.useState<
      OverridableStringUnion<AlertColor, AlertPropsColorOverrides>
    >("info");
  const [teleRestService, setTeleRestService] =
    React.useState<
      OverridableStringUnion<AlertColor, AlertPropsColorOverrides>
    >("info");
  const [cfsInventoryService, setCfsInventoryService] =
    React.useState<
      OverridableStringUnion<AlertColor, AlertPropsColorOverrides>
    >("info");
  const [painService, setPainService] =
    React.useState<
      OverridableStringUnion<AlertColor, AlertPropsColorOverrides>
    >("info");
  const [ipamService, setIpamService] =
    React.useState<
      OverridableStringUnion<AlertColor, AlertPropsColorOverrides>
    >("info");
  const [bandwidthService, setBandwidthService] =
    React.useState<
      OverridableStringUnion<AlertColor, AlertPropsColorOverrides>
    >("info");

  const getDhcpServiceStatus = () => {
    // Fetch DHCP service status from https://apim-api-gw.apps.eviny.no/dhcpstatus/v1.0/status
    fetch("https://apim-api-gw.apps.eviny.no/dhcpstatus/v1.0/status")
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "ok") {
          setDhcpService("success");
        } else {
          setDhcpService("error");
        }
      })
      .catch((error) => {
        console.error("Error fetching DHCP service status", error);
        setDhcpService("error");
      });
  };

  const getTeleRestServiceStatus = async () => {
    try {
      await configureOpenAPI(telematorOpenAPI);
      const response = await getCircuitById({
        id: telematorConfig.healthCheckCircuit,
      });
      console.log("Telerest Response:", response);
      setTeleRestService("success");
    } catch (error) {
      console.error("Error fetching TeleRest service status", error);
      setTeleRestService("error");
    }
  };

  const getCfsInventoryServiceStatus = async () => {
    try {
      await configureOpenAPI(cfsInventoryOpenAPI);
      const response = await EquipmentsService.equipmentsGetEquipmentLists({
        capabilities: "ACCESS",
        limit: 1,
        offset: 0,
      });
      console.log("CFS Inventory Response:", response);
      setCfsInventoryService("success");
    } catch (error) {
      console.error("Error fetching CFS Inventory service status", error);
      setCfsInventoryService("error");
    }
  };

  const getPainServiceStatus = async () => {
    try {
      await configureOpenAPI(PainOpenAPI);
      const response = await getIdentify({ id: "C100M-1234" });
      console.log("Pain Response:", response);
      setPainService("success");
    } catch (error) {
      console.error("Error fetching PAIN service status", error);
      setPainService("error");
    }
  };

  const getIpamServiceStatus = async () => {
    try {
      await configureOpenAPI(ipamOpenAPI);
      const response = await getAddressspaces();
      console.log("IPAM Response:", response);
      setIpamService("success");
    } catch (error) {
      console.error("Error fetching IPAM service status", error);
      setIpamService("error");
    }
  };

  const getBandwidthServiceStatus = async () => {
    try {
      await configureOpenAPI(bandwidthOpenAPI);
      const response = await getVersion(); // OK or should it get the proxy endpoint to get actual
      console.log("Bandwidth Response:", response);
      setBandwidthService("success");
    } catch (error) {
      console.error("Error fetching Bandwidth service status", error);
      setBandwidthService("error");
    }
  };

  // service map where name is displayed and getStatus is called to fetch the status
  // status is stored in state and displayed in the UI and used in the summary calculation useEffect
  const services = {
    dhcp: {
      name: "DHCP Service",
      getStatus: getDhcpServiceStatus,
      status: dhcpService,
    },
    teleRest: {
      name: "TeleRest",
      getStatus: getTeleRestServiceStatus,
      status: teleRestService,
    },
    cfsInventory: {
      name: "CFS Inventory",
      getStatus: getCfsInventoryServiceStatus,
      status: cfsInventoryService,
    },
    pain: {
      name: "Pain",
      getStatus: getPainServiceStatus,
      status: painService,
    },
    ipam: {
      name: "IPAM",
      getStatus: getIpamServiceStatus,
      status: ipamService,
    },
    bandwidth: {
      name: "Bandwidth",
      getStatus: getBandwidthServiceStatus,
      status: bandwidthService,
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      for (const service of Object.values(services)) {
        service.getStatus();
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    let operational = 0;
    let unhealthy = 0;
    let unknown = Object.keys(services).length;
    for (const service of Object.values(services)) {
      if (service.status === "success") {
        operational++;
        unknown--;
      } else if (service.status === "error") {
        unhealthy++;
        unknown--;
      }
    }
    setOperational(operational);
    setUnhealthy(unhealthy);
    setUnknown(unknown);
  }, [...Object.values(services).map((service) => service.status)]);

  const statusSummary = (
    <Container>
      <Typography variant="h2">Summary</Typography>
      <div style={{ display: "flex", gap: "1em" }}>
        {operational > 0 && (
          <Alert severity="success">Operational: {operational}</Alert>
        )}
        {unhealthy > 0 && (
          <Alert severity="error">Unhealthy: {unhealthy}</Alert>
        )}
        {unknown > 0 && <Alert severity="info">Unknown: {unknown}</Alert>}
      </div>
    </Container>
  );

  const operationalStatus = (
    <Container>
      <Typography variant="h2">Service Status</Typography>
      <Card style={{ padding: "1em" }}>
        <Stack>
          {Object.values(services)
            .sort((a, b) => a.name.localeCompare(b.name)) // Sort by service.name
            .map((service) => (
              <Alert severity={service.status} key={service.name}>
                <AlertTitle>{service.name}</AlertTitle>
              </Alert>
            ))}
        </Stack>
      </Card>
    </Container>
  );

  return (
    <Container>
      <Typography variant="h1">Operational Status</Typography>
      <div style={{ margin: "5em" }}>{statusSummary}</div>
      <div style={{ margin: "5em" }}>{operationalStatus}</div>
    </Container>
  );
}
