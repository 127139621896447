import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Link,
  Menu,
  MenuItem,
} from "@mui/material";
import WelcomeName from "./WelcomeName";
import SignInSignOutButton from "./SignInSignOutButton";
import { Link as RouterLink } from "react-router-dom";
import React, { useState } from "react";
import { Brightness4, Brightness7 } from "@mui/icons-material";
import { useThemeContext } from "../styles/themeContext";

const NavBar = () => {
  const { toggleTheme, theme } = useThemeContext();
  const [painAnchorEl, setPainAnchorEl] = useState<null | HTMLElement>(null);
  const [migreringAnchorEl, setMigreringAnchorEl] =
    useState<null | HTMLElement>(null);
  const [inventoryAnchorEl, setInventoryAnchorEl] =
    useState<null | HTMLElement>(null);

  const handlePainMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setPainAnchorEl(event.currentTarget);
  };

  const handlePainMenuClose = () => {
    setPainAnchorEl(null);
  };

  const handleMigreringMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMigreringAnchorEl(event.currentTarget);
  };

  const handleMigreringMenuClose = () => {
    setMigreringAnchorEl(null);
  };

  const handleInventoryMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setInventoryAnchorEl(event.currentTarget);
  };

  const handleInventoryMenuClose = () => {
    setInventoryAnchorEl(null);
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: "rgb(18, 68, 66)" }}>
        <Toolbar>
          <Typography style={{ flexGrow: 1 }}>
            <Link
              component={RouterLink}
              to="/"
              color="inherit"
              variant="h6"
              underline="none"
            >
              <img
                src={process.env.PUBLIC_URL + "/eviny-logo.svg"}
                alt="Hjem"
                height="32"
                width="32"
              />
            </Link>
          </Typography>
          <Typography style={{ flexGrow: 1 }}>
            <Link
              component={RouterLink}
              to="/points"
              color="inherit"
              variant="h6"
              underline="none"
            >
              Punkt
            </Link>
          </Typography>
          <Typography style={{ flexGrow: 1 }}>
            <Link
              component={RouterLink}
              to="/addresses"
              color="inherit"
              variant="h6"
              underline="none"
            >
              Adresser
            </Link>
          </Typography>
          <Typography style={{ flexGrow: 1 }}>
            <Link
              component={RouterLink}
              to="/services/"
              color="inherit"
              variant="h6"
              underline="none"
            >
              Tjenester
            </Link>
          </Typography>
          <Typography style={{ flexGrow: 1 }}>
            <Link
              component="button"
              onClick={handlePainMenuClick}
              color="inherit"
              variant="h6"
              underline="none"
            >
              Pain
            </Link>
          </Typography>
          <Menu
            anchorEl={painAnchorEl}
            open={Boolean(painAnchorEl)}
            onClose={handlePainMenuClose}
          >
            <MenuItem
              component={RouterLink}
              to="/pain"
              onClick={handlePainMenuClose}
            >
              Pain Visualisation
            </MenuItem>
            <MenuItem
              component={RouterLink}
              to="/pain/adjacency"
              onClick={handlePainMenuClose}
            >
              Pain Adjacency
            </MenuItem>
          </Menu>
          <Typography style={{ flexGrow: 1 }}>
            <Link
              component="button"
              onClick={handleMigreringMenuClick}
              color="inherit"
              variant="h6"
              underline="none"
            >
              Migrering
            </Link>
          </Typography>
          <Menu
            anchorEl={migreringAnchorEl}
            open={Boolean(migreringAnchorEl)}
            onClose={handleMigreringMenuClose}
          >
            <MenuItem
              component={RouterLink}
              to="/migrering/nni"
              onClick={handleMigreringMenuClose}
            >
              NNI oversikt
            </MenuItem>
            <MenuItem
              component={RouterLink}
              to="/migrering/ipam"
              onClick={handleMigreringMenuClose}
            >
              IPAM oversikt
            </MenuItem>
          </Menu>
          <Typography style={{ flexGrow: 1 }}>
            <Link
              component="button"
              onClick={handleInventoryMenuClick}
              color="inherit"
              variant="h6"
              underline="none"
            >
              Inventory
            </Link>
          </Typography>
          <Menu
            anchorEl={inventoryAnchorEl}
            open={Boolean(inventoryAnchorEl)}
            onClose={handleInventoryMenuClose}
          >
            <MenuItem
              component={RouterLink}
              to="/inventory/import"
              onClick={handleInventoryMenuClose}
            >
              Inventory Import Reports
            </MenuItem>
          </Menu>
          <Typography style={{ flexGrow: 1 }}>
            <Link
              component={RouterLink}
              to="/status"
              color="inherit"
              variant="h6"
              underline="none"
            >
              Status
            </Link>
          </Typography>
          <WelcomeName />
          <SignInSignOutButton />
          <IconButton onClick={toggleTheme} color="inherit">
            {theme.palette.mode === "dark" ? <Brightness7 /> : <Brightness4 />}
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default NavBar;
