import React from "react";

import { Tooltip } from "@mui/material";

import {
  Check,
  HeartBroken,
  Delete,
  AutoDelete,
  QuestionMark,
  Upcoming,
  ReceiptLong,
} from "@mui/icons-material";

export const ServiceStatusIcon = ({ status }: { status: string }) => {
  let icon = (
    <QuestionMark sx={{ color: "rgb(200, 200, 200)" }} fontSize="small" />
  );
  const toolTipTitle = `Status: ${status}`;
  switch (status) {
    case "ANY":
      icon = (
        <QuestionMark sx={{ color: "rgb(200, 200, 200)" }} fontSize="small" />
      );
      break;
    case "UNUSED":
      icon = <Upcoming sx={{ color: "rgb(0, 0, 200)" }} fontSize="small" />;
      break;
    case "PROD":
      icon = <Check sx={{ color: "rgb(0, 200, 0)" }} fontSize="small" />;
      break;
    case "RESERVED":
      icon = <ReceiptLong sx={{ color: "rgb(0, 0, 200)" }} fontSize="small" />;
      break;
    case "DELETED":
      icon = <Delete sx={{ color: "rgb(200, 0, 0)" }} fontSize="small" />;
      break;
    case "BROKEN":
      icon = <HeartBroken sx={{ color: "rgb(200, 0, 0)" }} fontSize="small" />;
      break;
    case "GRACE":
      icon = <AutoDelete sx={{ color: "rgb(200, 200, 0)" }} fontSize="small" />;
      break;
  }
  return <Tooltip title={toolTipTitle}>{icon}</Tooltip>;
};
