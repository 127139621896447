import React from "react";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";

interface DropdownListProps {
  options: string[];
  selectedOption: string;
  setSelectedOption: (value: string) => void;
  placeholder?: string;
}

const DropdownList: React.FC<DropdownListProps> = ({
  options,
  selectedOption,
  setSelectedOption,
  placeholder,
}) => {
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Select
        value={selectedOption}
        onChange={(e) => setSelectedOption(e.target.value as string)}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        sx={{
          color:  theme.palette.primary,
          "& .MuiSelect-icon": {
            color: "white",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          paddingLeft: "40px",
        }}
      >
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default DropdownList;
