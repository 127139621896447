import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import { CheckCircle, Error, Help } from "@mui/icons-material";
import { cfsCustLineGetCustLineOperationalInfoById } from "../gen/cfsCustlineClient/services.gen";
import {
  GetLineOperationalInfoResponse,
  LineServiceAssuranceV1PerformanceData,
} from "../gen/cfsCustlineClient/types.gen";
import { cfsInternetGetInternetLineOperationalInfoById } from "../gen/cfsInternetClient/services.gen";
import { cfsIpVpnGetIpVpnLineOperationalInfoById } from "../gen/cfsIpVpnClient/services.gen";
import { cfsL2CircuitGetL2LineOperationalInfoById } from "../gen/cfsL2CircuitClient/services.gen";
import { getOverseer } from "../gen/bandwidthClient/sdk.gen";
import { OpenAPI as CustLineOpenAPI } from "../gen/cfsCustlineClient/core/OpenAPI";
import { OpenAPI as InternetOpenAPI } from "../gen/cfsInternetClient/core/OpenAPI";
import { OpenAPI as IpVpnOpenAPI } from "../gen/cfsIpVpnClient/core/OpenAPI";
import { OpenAPI as L2CircuitOpenAPI } from "../gen/cfsL2CircuitClient/core/OpenAPI";
import { OpenAPI as BandwidthOpenAPI } from "../gen/bandwidthClient/core/OpenAPI";
import { configureOpenAPI } from "../utils/openApi";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  PortmfsResult,
  GetOverseerResponse,
} from "../gen/bandwidthClient/types.gen";

const arrow = true;
const overSeerDefaultPeriod = "4h";

const fetchOverseerData = async (
  extReference: string,
  mgmtIp: string,
  period: string,
) => {
  try {
    await configureOpenAPI(BandwidthOpenAPI); // Ensure OpenAPI is configured before making the request
    const response = await getOverseer({
      extReference,
      mgmtIp,
      period,
    });
    return response;
  } catch (error) {
    console.error(`Could not fetch overseer data!`);
    return {};
  }
};

const fetchCustlineOperationalStatus = async (serviceId: string) => {
  try {
    await configureOpenAPI(CustLineOpenAPI); // Ensure OpenAPI is configured before making the request
    const response = await cfsCustLineGetCustLineOperationalInfoById({
      id: serviceId,
    });
    return response;
  } catch (error) {
    console.error(`Could not fetch custline operational status!`);
    return {};
  }
};

const fetchInternetlineOperationalStatus = async (serviceId: string) => {
  try {
    await configureOpenAPI(InternetOpenAPI); // Ensure OpenAPI is configured before making the request
    const response = await cfsInternetGetInternetLineOperationalInfoById({
      id: serviceId,
    });
    return response;
  } catch (error) {
    console.error(`Could not fetch internet operational status!`);
    return {};
  }
};

const fetchIpVpnlineOperationalStatus = async (serviceId: string) => {
  try {
    await configureOpenAPI(IpVpnOpenAPI); // Ensure OpenAPI is configured before making the request
    const response = await cfsIpVpnGetIpVpnLineOperationalInfoById({
      id: serviceId,
    });
    return response;
  } catch (error) {
    console.error(`Could not fetch ipvpn operational status!`);
    return {};
  }
};

const fetchL2P2PlineOperationalStatus = async (serviceId: string) => {
  try {
    await configureOpenAPI(L2CircuitOpenAPI); // Ensure OpenAPI is configured before making the request
    const response = await cfsL2CircuitGetL2LineOperationalInfoById({
      id: serviceId,
    });
    return response;
  } catch (error) {
    console.error(`Could not fetch l2circuit operational status!`);
    return {};
  }
};

export const OperationalStatusCustline = ({
  serviceId,
}: {
  serviceId: string;
}) => {
  const [status, setStatus] = useState<
    GetLineOperationalInfoResponse | undefined
  >(undefined);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getStatus = async () => {
      try {
        const data = await fetchCustlineOperationalStatus(serviceId);
        setStatus(data);
      } catch (err) {
        setError(err.message);
      }
    };

    getStatus();
  }, [serviceId]);

  if (error) {
    console.error(error);
  }

  return <OperationalStatusComponent status={status} />;
};

export const LegacyOperationalStatus = ({
  interfaceName,
  mgmtIp,
}: {
  interfaceName: string;
  mgmtIp: string;
}) => {
  const [status, setStatus] = useState<
    GetLineOperationalInfoResponse | undefined
  >(undefined);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getStatus = async () => {
      try {
        const data = await fetchOverseerData(
          interfaceName,
          mgmtIp,
          overSeerDefaultPeriod,
        );
        const OperInfo =
          convertOverseerData2GetLineOperationalInfoResponse(data);
        setStatus(OperInfo);
      } catch (err) {
        setError(err.message);
      }
    };

    getStatus();
  }, [interfaceName, mgmtIp]);

  if (error) {
    console.error(error);
  }

  return <OperationalStatusComponent status={status} />;
};

const convertOverseerStatus = (
  status: number | undefined,
): "OTHER_INT_STATE" | "UP" | "DOWN" | "TESTING" | "UNKNOWN" | undefined => {
  switch (status) {
    case 1:
      return "UP";
    case 2:
      return "DOWN";
    case 3:
      return "TESTING";
    default:
      return "UNKNOWN";
  }
};

const convertPortmfsResult2LineServiceAssuranceV1PerformanceData = (
  perf: PortmfsResult[],
): {
  status: "OK" | "WARNING";
  pdata: LineServiceAssuranceV1PerformanceData[];
} => {
  let status: "OK" | "WARNING" = "OK";
  const pfBpsIn: LineServiceAssuranceV1PerformanceData = {
    type: "BPS_IN",
    data: [],
  };
  const pfBpsOut: LineServiceAssuranceV1PerformanceData = {
    type: "BPS_OUT",
    data: [],
  };
  const pfErrorIn: LineServiceAssuranceV1PerformanceData = {
    type: "ERROR_IN",
    data: [],
  };
  const pfErrorOut: LineServiceAssuranceV1PerformanceData = {
    type: "ERROR_OUT",
    data: [],
  };

  for (const result of perf) {
    const bpsIn = result.im_BitsPerSecondIn
      ? Number(result.im_BitsPerSecondIn)
      : 0;
    const bpsOut = result.im_BitsPerSecondOut
      ? Number(result.im_BitsPerSecondOut)
      : 0;
    const errIn = result.im_ErrorsIn ? Number(result.im_ErrorsIn) : 0;
    const errOut = result.im_ErrorsOut ? Number(result.im_ErrorsOut) : 0;
    pfBpsIn?.data?.push({
      time_stamp_epoch: result.Timestamp ? Number(result.Timestamp) : undefined,
      value: bpsIn,
    });
    pfBpsOut?.data?.push({
      time_stamp_epoch: result.Timestamp ? Number(result.Timestamp) : undefined,
      value: bpsOut,
    });

    pfErrorIn?.data?.push({
      time_stamp_epoch: result.Timestamp ? Number(result.Timestamp) : undefined,
      value: errIn,
    });
    pfErrorOut?.data?.push({
      time_stamp_epoch: result.Timestamp ? Number(result.Timestamp) : undefined,
      value: result.im_ErrorsOut ? Number(result.im_ErrorsOut) : 0,
    });
    if (errIn > 0 || errOut > 0) {
      status = "WARNING";
    }
  }
  const pdata: LineServiceAssuranceV1PerformanceData[] = [
    pfBpsIn,
    pfBpsOut,
    pfErrorIn,
    pfErrorOut,
  ];
  return { status, pdata };
};

const convertOverseerData2GetLineOperationalInfoResponse = (
  data: GetOverseerResponse,
): GetLineOperationalInfoResponse => {
  if (!data?.d?.results) {
    console.error("Unexpected data format in overseer data");
    return {};
  }
  if (data.d.results.length !== 1) {
    console.error(
      "Unexpected data format in overseer data, expected 1 result, got ",
      data.d.results.length,
    );
    return {};
  }
  const result = data.d.results[0];
  if (!result.portmfs?.results) {
    console.error("Unexpected data format in overseer data");
    return {};
  }
  const { status, pdata } =
    convertPortmfsResult2LineServiceAssuranceV1PerformanceData(
      result.portmfs.results,
    );
  const adminStatus = convertOverseerStatus(result.AdminStatus);
  const operationalStatus = convertOverseerStatus(result.OperStatus);
  let sa_point_status:
    | "OK"
    | "WARNING"
    | "UNKNOWN"
    | "ERROR"
    | "NOTOPERATIONAL"
    | "OTHER_SAPOINTSTATUS"
    | undefined = "UNKNOWN";
  // sa_point_status: should be the worst status of status and the admin and operationa status
  if (status === "OK") {
    sa_point_status = "OK";
  }
  if (status === "WARNING") {
    sa_point_status = "WARNING";
  }
  if (operationalStatus === "DOWN") {
    sa_point_status = "ERROR";
  }
  if (adminStatus === "DOWN") {
    sa_point_status = "NOTOPERATIONAL";
  }
  return {
    description: result.Alias,
    admin_status: adminStatus,
    operational_status: operationalStatus,
    sa_point_status: sa_point_status,
    speed_in: result.SpeedIn,
    speed_out: result.SpeedOut,
    performance_data: pdata,
  };
};

export const OperationalStatusInternetline = ({
  serviceId,
}: {
  serviceId: string;
}) => {
  const [status, setStatus] = useState<
    GetLineOperationalInfoResponse | undefined
  >(undefined);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getStatus = async () => {
      try {
        const data = await fetchInternetlineOperationalStatus(serviceId);
        setStatus(data);
      } catch (err) {
        setError(err.message);
      }
    };

    getStatus();
  }, [serviceId]);

  if (error) {
    console.error(error);
  }

  return <OperationalStatusComponent status={status} />;
};

export const OperationalStatusIpVpnline = ({
  serviceId,
}: {
  serviceId: string;
}) => {
  const [status, setStatus] = useState<
    GetLineOperationalInfoResponse | undefined
  >(undefined);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getStatus = async () => {
      try {
        const data = await fetchIpVpnlineOperationalStatus(serviceId);
        setStatus(data);
      } catch (err) {
        setError(err.message);
      }
    };

    getStatus();
  }, [serviceId]);

  if (error) {
    console.error(error);
  }

  return <OperationalStatusComponent status={status} />;
};

export const OperationalStatusL2line = ({
  serviceId,
}: {
  serviceId: string;
}) => {
  const [status, setStatus] = useState<
    GetLineOperationalInfoResponse | undefined
  >(undefined);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getStatus = async () => {
      try {
        const data = await fetchL2P2PlineOperationalStatus(serviceId);
        setStatus(data);
      } catch (err) {
        setError(err.message);
      }
    };

    getStatus();
  }, [serviceId]);

  if (error) {
    console.error(error);
  }

  return <OperationalStatusComponent status={status} />;
};

const getPerformanceData = (
  data: LineServiceAssuranceV1PerformanceData[] | undefined,
  type: "BPS_IN" | "BPS_OUT" | "ERROR_IN" | "ERROR_OUT",
) => {
  return data?.find((d) => d.type === type)?.data || [];
};

const renderInterfaceTraffic = (dataIn, dataOut, errorIn, errorOut) => {
  // Combine all data sets to find the overall min and max values
  const combinedData = [...dataIn, ...dataOut, ...errorIn, ...errorOut];
  const minValue = Math.min(
    ...combinedData.map((d) => d.value).filter((value) => value !== undefined),
  );
  const maxValue = Math.max(
    ...combinedData.map((d) => d.value).filter((value) => value !== undefined),
  );

  // Determine the scale and unit
  let scale = 1;
  let unit = "BPS";
  if (maxValue >= 1e9) {
    scale = 1e9;
    unit = "GBPS";
  } else if (maxValue >= 1e6) {
    scale = 1e6;
    unit = "MBPS";
  } else if (maxValue >= 1e3) {
    scale = 1e3;
    unit = "KBPS";
  }

  // Add padding to the min and max values
  const padding = (maxValue - minValue) * 0.1;
  const domain = [(minValue - padding) / scale, (maxValue + padding) / scale];

  return (
    <div
      style={{
        backgroundColor: "rgba(51, 51, 51, 1)",
        padding: "10px",
        borderRadius: "5px",
        width: "100%",
        height: "100%",
      }}
    >
      <ResponsiveContainer width={"100%"} height={"100%"}>
        <LineChart
          data={dataIn.map((d) => ({
            ...d,
            value: d.value !== undefined ? d.value / scale : 0,
          }))}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#444" />
          <XAxis
            dataKey="time_stamp_epoch"
            tickFormatter={(tick) => new Date(tick * 1000).toLocaleTimeString()}
            type="number"
            domain={["dataMin", "dataMax"]}
            tick={{ fill: "#fff" }}
            label={{
              value: "Time",
              position: "insideBottomRight",
              offset: -5,
              fill: "#fff",
            }}
          />
          <YAxis
            domain={[domain[0] > 0 ? domain[0] : 0, domain[1]]} // Conditional check for to ensure no negative values
            tickFormatter={(tick) =>
              tick !== undefined ? tick.toFixed(2) : ""
            }
            tick={{ fill: "#fff" }}
            label={{
              value: unit,
              angle: -90,
              position: "insideLeft",
              fill: "#fff",
            }}
          />
          <RechartsTooltip
            contentStyle={{ backgroundColor: "#555", border: "none" }}
            itemStyle={{ color: "#fff" }}
            formatter={(value, name, _props) => [
              `${value !== undefined ? (value as number).toFixed(2) : ""} ${unit}`,
              name,
            ]}
            labelFormatter={(label) =>
              new Date(label * 1000).toLocaleTimeString()
            }
          />
          <Legend wrapperStyle={{ color: "#fff" }} />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#8884d8"
            name="BPS_IN"
          />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#82ca9d"
            name="BPS_OUT"
            data={dataOut.map((d) => ({
              ...d,
              value: d.value !== undefined ? d.value / scale : 0,
            }))}
          />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#ff0000"
            name="ERROR_IN"
            data={errorIn.map((d) => ({
              ...d,
              value: d.value !== undefined ? d.value / scale : 0,
            }))}
          />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#ffa500"
            name="ERROR_OUT"
            data={errorOut.map((d) => ({
              ...d,
              value: d.value !== undefined ? d.value / scale : 0,
            }))}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

const formatSpeed = (speed) => {
  if (speed === undefined) {
    return "N/A";
  }
  const numericSpeed = Number(speed);
  if (!Number.isFinite(numericSpeed)) {
    console.error("Invalid speed value", speed);
    return speed;
  }
  if (numericSpeed >= 1e9) {
    return `${(numericSpeed / 1e9).toFixed(0)} Gbps`;
  } else if (numericSpeed >= 1e6) {
    return `${(numericSpeed / 1e6).toFixed(0)} Mbps`;
  } else if (numericSpeed >= 1e3) {
    return `${(numericSpeed / 1e3).toFixed(0)} Kbps`;
  } else {
    return `${numericSpeed.toFixed(0)} Bps`;
  }
};

const getStatusTooltipContent = (status: GetLineOperationalInfoResponse) => {
  const dataIn = getPerformanceData(status.performance_data, "BPS_IN");
  const dataOut = getPerformanceData(status.performance_data, "BPS_OUT");
  const errorIn = getPerformanceData(status.performance_data, "ERROR_IN");
  const errorOut = getPerformanceData(status.performance_data, "ERROR_OUT");
  if (
    status === undefined ||
    status === null ||
    Object.keys(status).length === 0
  ) {
    return "No data available";
  }
  if (status.sa_point_status === "UNKNOWN") {
    return "loading...";
  }
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div>Description: {status.description}</div>
      <div>Admin Status: {status.admin_status}</div>
      <div>Operational Status: {status.operational_status}</div>
      <div>Evaluated Status: {status.sa_point_status}</div>
      <div>Speed In: {formatSpeed(status.speed_in)}</div>
      <div>Speed Out: {formatSpeed(status.speed_out)}</div>
      <div style={{ width: "700px", height: "300px" }}>
        {renderInterfaceTraffic(dataIn, dataOut, errorIn, errorOut)}
      </div>
    </div>
  );
};

const graphTooltip = (title, children) => (
  <Tooltip
    title={title}
    arrow
    slotProps={{
      popper: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [-150, 0],
            },
          },
        ],
      },
    }}
  >
    {children}
  </Tooltip>
);

const OperationalStatusComponent = ({
  status,
}: {
  status: GetLineOperationalInfoResponse | undefined;
}) => {
  if (!status) {
    status = { sa_point_status: "UNKNOWN" };
  }
  switch (status.sa_point_status) {
    case "OK":
      return graphTooltip(
        getStatusTooltipContent(status),
        <CheckCircle
          style={{
            color: "lightgreen",
            width: 30,
            height: 30,
          }}
        />,
      );
    case "ERROR":
      return graphTooltip(
        getStatusTooltipContent(status),
        <Error
          style={{
            color: "red",
            width: 30,
            height: 30,
          }}
        />,
      );
    case "WARNING":
      return graphTooltip(
        getStatusTooltipContent(status),
        <Error
          style={{
            color: "orange",
            width: 30,
            height: 30,
          }}
        />,
      );
    case "NOTOPERATIONAL":
      return graphTooltip(
        getStatusTooltipContent(status),
        <Help
          style={{
            color: "blue",
            width: 30,
            height: 30,
          }}
        />,
      );
    default:
      return (
        <Tooltip title={getStatusTooltipContent(status)} arrow>
          <Help
            style={{
              color: "gray",
              width: 30,
              height: 30,
            }}
          />
        </Tooltip>
      );
  }
};
