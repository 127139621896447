import React, { ReactElement, useEffect, useState, useRef } from "react";
import Draggable, { DraggableEvent, DraggableData } from "react-draggable";
import { useTheme } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import { DragIndicator, Close } from "@mui/icons-material";

export type DraggableTileProps = {
  title: string;
  children: React.ReactNode;
  hiddenWhenEmpty?: boolean;
  defaultPosition?: { x: number; y: number };
};

export const DraggableTile: React.FC<DraggableTileProps> = ({
  title,
  children,
  hiddenWhenEmpty,
  defaultPosition,
}): ReactElement => {
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState(defaultPosition);
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      if (!hiddenWhenEmpty || children) {
        setIsVisible(true);
      }
    }
  }, []);

  // Update view when title changes
  useEffect(() => {
    if (title) {
      if (!isVisible) {
        // window has been closed open the new at default position
        setPosition(defaultPosition);
      }
      if (hiddenWhenEmpty) {
        if (children) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      }
      return;
    }
    setIsVisible(false);
  }, [title]);

  const ignoreDrag = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleDrag = (e: DraggableEvent, data: DraggableData) => {
    setPosition({ x: data.x, y: data.y });
  };

  return (
    <Draggable handle=".handle" position={position} onDrag={handleDrag}>
      <div
        className="handle"
        style={{
          position: "absolute",
          backgroundColor: theme.palette.background.paper,
          padding: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "4px",
          display: isVisible ? "block" : "none",
          cursor: "move",
          zIndex: 1000,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <DragIndicator
            style={{
              padding: "5px",
              marginRight: "5px",
            }}
          />
          <div style={{ width: "100%" }} />
          <h2
            onMouseDown={ignoreDrag}
            style={{
              margin: "0",
              fontSize: "16px",
              textAlign: "center",
              whiteSpace: "nowrap",
              display: "inline-block",
              userSelect: "text",
              cursor: "auto",
            }}
          >
            {title}
          </h2>
          <div style={{ width: "100%" }} />
          <IconButton
            size="small"
            onClick={() => setIsVisible(false)}
            style={{ marginLeft: "auto" }}
          >
            <Close />
          </IconButton>
        </div>
        <div onMouseDown={ignoreDrag} style={{ cursor: "auto" }}>
          {children}
        </div>
      </div>
    </Draggable>
  );
};
