import React, { useEffect, useState } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Typography, Box } from '@mui/material';
import { OpenAPI } from "../../gen/ipamClient-1.2.1/core/OpenAPI.ts";
import { getAssignments } from "../../gen/ipamClient-1.2.1/sdk.gen.ts";
import { configureOpenAPI } from "../../utils/openApi"
import { toast } from 'react-toastify';
import { ipamConfig } from '../../authConfig.ts';

const Ipam = () => {
    const [ipAddresses, setIpAddresses] = useState<
        Awaited<ReturnType<typeof getAssignments>>
    >();
    const [loading, setLoading] = useState(true);

    const fetchIPAddresses = async () => {
        await configureOpenAPI(OpenAPI);
        const ipAddresses = await getAssignments();
        setIpAddresses(ipAddresses);
    };

    useEffect(() => {
        const loadIPAddresses = async () => {
            try {
                await fetchIPAddresses();
            } catch (error) {
                console.error("Error fetching IP addresses:", error);
                toast.error("Error fetching IP addresses");
            } finally {
                setLoading(false);
            }
        };

        loadIPAddresses();
    }, []);

    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    const isSubnetOf = (ipAddress: string, allocation: string): boolean => {
        const [ip, ipPrefixLength] = ipAddress.split('/');
        const [alloc, allocPrefixLength] = allocation.split('/');

        if (!ipPrefixLength || !allocPrefixLength) return false;

        const ipPrefix = parseInt(ipPrefixLength, 10);
        const allocPrefix = parseInt(allocPrefixLength, 10);

        if (ipPrefix < allocPrefix) return false;

        const ipBinary = ip.split('.').map(octet => parseInt(octet, 10).toString(2).padStart(8, '0')).join('');
        const allocBinary = alloc.split('.').map(octet => parseInt(octet, 10).toString(2).padStart(8, '0')).join('');

        return ipBinary.substring(0, allocPrefix) === allocBinary.substring(0, allocPrefix);
    };

    return (
        <div>
            <Box mb={10}>
                <Typography variant="h4">IPAM Details</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Category</TableCell>
                            <TableCell>Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ipamConfig.allocations.map(allocation => (
                            <React.Fragment key={allocation}>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <Typography variant="h6">{allocation}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Active</TableCell>
                                    <TableCell>
                                        {ipAddresses?.assignments?.filter(ip => ip.address && !ip.in_quarantine && isSubnetOf(ip.address!, allocation)).length || 0}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Quarantine</TableCell>
                                    <TableCell>
                                        {ipAddresses?.assignments?.filter(ip => ip.address && ip.in_quarantine && isSubnetOf(ip.address!, allocation)).length || 0}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Conflict</TableCell>
                                    <TableCell>
                                        {ipAddresses?.assignments?.filter(ip => ip.ext_reference && ip.ext_reference.toLowerCase() === 'conflict' && isSubnetOf(ip.address!, allocation)).length || 0}
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </Box>

            <Box mb={10}>
                <Typography variant="h4">Usage</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>IP Version</TableCell>
                            <TableCell>Usage</TableCell>
                            <TableCell>Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {['4', '6'].map(version => (
                            ['Linknett', 'Kundenett'].map(usage => (
                                <TableRow key={`${version}-${usage}`}>
                                    <TableCell>{version}</TableCell>
                                    <TableCell>{usage}</TableCell>
                                    <TableCell>
                                        {ipAddresses?.assignments?.filter(ip => ip.version === Number(version) && ip.usage === usage).length || 0}
                                    </TableCell>
                                </TableRow>
                            ))
                        ))}
                    </TableBody>
                </Table>
            </Box>

            <Box mb={10}>
                <Typography variant="h4">Circuits</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>External Reference</TableCell>
                            <TableCell>IP Address Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ipAddresses?.assignments?.reduce((acc, ip) => {
                            const extRef = ip.ext_reference || 'Unknown';
                            if (!acc[extRef]) {
                                acc[extRef] = 0;
                            }
                            acc[extRef]++;
                            return acc;
                        }, {} as Record<string, number>) && Object.entries(ipAddresses.assignments.reduce((acc, ip) => {
                            const extRef = ip.ext_reference || 'Unknown';
                            if (!acc[extRef]) {
                                acc[extRef] = 0;
                            }
                            acc[extRef]++;
                            return acc;
                        }, {} as Record<string, number>)).map(([extRef, count]) => (
                            <TableRow key={extRef}>
                                <TableCell>{extRef}</TableCell>
                                <TableCell>{count}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </div>
    );
};

export default Ipam;