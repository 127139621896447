export const humanReadableAverageSpeed = (speed: number): string => {
    // Average speed over 24h
    const bps = Math.floor(speed / 86400); // 24h in seconds
    const ret = humanReadableSpeed(bps);
    return ret + "/s";
}


export const humanReadableSpeed = (speed: number): string => {
    if (speed > 1000000000000000) {
        return `${(speed / 1000000000000000).toFixed(2)} Pb`;
    }
    if (speed > 1000000000000) {
        return `${(speed / 1000000000000).toFixed(2)} Tb`;
    }
    if (speed > 1000000000) {
        return `${(speed / 1000000000).toFixed(2)} Gb`;
    }
    if (speed > 1000000) {
        return `${(speed / 1000000).toFixed(2)} Mb`;
    }
    if (speed > 1000) {
        return `${(speed / 1000).toFixed(2)} Kb`;
    }
    return `${speed} b`;
}

export const speed2Number = (speed: string): number => {
    const match = speed.match(/^(\d+)([a-zA-Z]+)$/);
    if (!match) {
        return 0;
    }
    const value = parseInt(match[1], 10);
    const unit = match[2];
    switch (unit) {
        case "G":
            return value * 1000000000;
        case "M":
            return value * 1000000;
        case "K":
            return value * 1000;
        default:
            return value;
    }
};