import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const COLORS = [
    '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A28BFE', '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
    '#FF9F40', '#FFCD56', '#4BC0C0', '#36A2EB', '#FF6384', '#A28BFE', '#FF8042', '#FFBB28', '#00C49F', '#0088FE'
  ];

const PieChartComponent = ({ data, viewLabel }) => {
  const filteredData = data.filter((item) => item.value > 0);

  const label = viewLabel ? ({ percent }) => `${(percent * 100).toFixed(0)}%`: false;

  return (
    <PieChart width={350} height={350}>
      <Pie
        data={filteredData}
        cx={200}
        cy={200}
        labelLine={false}
        label={label}
        outerRadius={80}
        fill="#8884d8"
        dataKey="value"
      >
        {filteredData.map((_, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
      <Legend />
    </PieChart>
  );
};

export default PieChartComponent;