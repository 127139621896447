import { Routes, Route, useNavigate } from "react-router-dom";
// Material-UI imports
import Grid from "@mui/material/Grid";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { CustomNavigationClient } from "./utils/NavigationClient";

// Sample app imports
import { PageLayout } from "./ui-components/PageLayout";
import { Home } from "./pages/Home";
import { Profile } from "./pages/Profile";
import { Points } from "./pages/Points";
import { PainVisualisation, PainAdjacency } from "./pages/Pain";
import { Nni } from "./pages/migrering/nni";
import Ipam from "./pages/migrering/ipam";
import React from "react";
import Addresses from "./pages/Addresses";
import Services from "./pages/Services";
import { Addressspaces } from "./pages/Ipam/Addressspaces";
import InventoryImport from "./pages/InventoryImport";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Status } from "./pages/Status";

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      {/* <ThemeContextProvider> */}
      <PageLayout>
        <Grid container justifyContent="center">
          <AuthenticatedTemplate>
            <Pages />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <div>Log inn for å se ting og tang.</div>
          </UnauthenticatedTemplate>
        </Grid>
      </PageLayout>
      {/* </ThemeContextProvider> */}
    </MsalProvider>
  );
}

const Spacer = ({ children }: { children: React.ReactNode }): React.JSX.Element => (
  <>
  <br/><br/>
  {children}
  </>
)

function Pages() {
  return (
    <Routes>
      <Route path="/profile" element={<Spacer><Profile /></Spacer>} />
      <Route path="/" element={<Spacer><Home /></Spacer>} />
      <Route path="/points" element={<Spacer><Points /></Spacer>} />
      <Route path="/addresses" element={<Spacer><Addresses /></Spacer>} />
      <Route path="/services/:serviceName?" element={<Spacer><Services /></Spacer>} />
      <Route path="/ipam" element={<Spacer><Addressspaces /></Spacer>} />
      <Route path="/pain/adjacency" element={<Spacer><PainAdjacency /></Spacer>} />
      <Route path="/pain" element={<PainVisualisation />} />
      <Route path="/migrering/nni" element={<Spacer><Nni /></Spacer>} />
      <Route path="/migrering/ipam" element={<Spacer><Ipam /></Spacer>} />
      <Route path="/inventory/import" element={<Spacer><InventoryImport /></Spacer>} />
      <Route path="/status" element={<Spacer><Status /></Spacer>} />
    </Routes>
  );
}

export default App;
