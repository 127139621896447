import { getCircuitsByNumber } from "../../gen/ipamClient-1.2.1/sdk.gen.ts";
import { Assignment } from "../../gen/ipamClient-1.2.1/types.gen.ts";
import { configureOpenAPI } from "../../utils/openApi"
import { OpenAPI } from "../../gen/ipamClient-1.2.1/core/OpenAPI.ts";
import { Service, ServiceParameter } from "../../gen/cfsInventoryClient/types.gen.ts";

export const getAssignmentByCircuitId = async (circuitNumber: string) => {
    circuitNumber = circuitNumber.toUpperCase();
    await configureOpenAPI(OpenAPI);
    const number = btoa(circuitNumber);
    const assignments = await getCircuitsByNumber({ number });
    return assignments;
}

export const getAssignmentByCircuitIdAsServices = async (circuitNumber: string): Promise<Service[]> => {
    const assignments: any = await getAssignmentByCircuitId(circuitNumber);
    const services: Service[] = [];
    if (assignments?.assignments) {
        assignments.assignments.map((assignment: Assignment) => {
            let state: "ANY" | "UNUSED" | "PROD" | "RESERVED" | "DELETED" | "BROKEN" | "GRACE" = "PROD";
            if (assignment.in_quarantine) {
                state = "GRACE";
            } else if (assignment.is_free) {
                state = "UNUSED";
            } else if (assignment.released && assignment.released !== "None") {
                state = "DELETED";
            }
            const serviceParameter: ServiceParameter = {
                name: `${assignment.usage?.toUpperCase() || ""}-IPV${assignment.version?.toString()}`,
                value: assignment.address || "",
                type: "IP_ADDRESS",
                created_at: assignment.assigned || "",
                updated_at: assignment.assigned || "",
                id: `${assignment.id}`,
            }

            const service: Service = {
                id: `${assignment.id}`,
                display_name: `Subnet ${assignment.id}`,
                service_type: "IP_SUBNET",
                state,
                parameters: [serviceParameter],
                created_at: assignment.assigned || "",
                updated_at: assignment.assigned || "",
                is_cfs: false,
                reservation_ext_reference: assignment.ext_reference || "",
            }
            services.push(service);
        });
    }
    return services;
}