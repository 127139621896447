import React, { useEffect, useState } from 'react';
import {getProxyByProxyPath as getOdata} from "../../gen/bandwidthClient/sdk.gen"
import {configureOpenAPI} from "../../utils/openApi"
import {OpenAPI} from "../../gen/bandwidthClient"
import { toast } from "react-toastify";
import { fetchResourceEquipmentExternalIdsByExternalIds } from '../../utils/inventory/identify';
import { EquipmentSummaryWithPopover } from '../../ui-components/Equipment';
import { LegacyOperationalStatus } from '../../ui-components/OperationalStatus';
import { humanReadableSpeed, humanReadableAverageSpeed, speed2Number } from '../../utils/formatSpeed';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableSortLabel,
    Container,
    Typography,
    Skeleton,
    Popover,
    Button,
    LinearProgress,
    Box,
    Alert,
  } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import PieChartComponent from '../../ui-components/PieChartComponent';

type BandWidthInterface = {
    Name: string;
    Alias: string;
    SpeedIn: number;
    SpeedOut: number;
    OperStatus: number;
    AdminStatus: number;
    RetireTime: string | null;
    device: {
        SysName: string;
        PrimaryIPAddress: string;
        ID: string;
        LifeCycleState: string;
    };
    portmfs: {
        results: {
            im_Bits: number;
        }[];
    };
    description: { // This is a custom field
        cid: string,
        provider: string,
        speed: string,
        type: string,
        description: string
    },
}

type BandWidthResponse = {
    d: {
        results: BandWidthInterface[];
    },
}

export const Nni= () =>{
    const [nnis, setNnis] = useState<BandWidthInterface[]>([]);
    
    useEffect(() => {
        const fetchNNIs = async () => {
            const nnis = await getActiveNNIs();
            setNnis(nnis);
        };
        fetchNNIs();
    }, []);

    return (
        <Container>
            <Typography variant="h1">Active NNIs ({nnis.length})</Typography>
            <ExtractedFromNetworkInfo />
            <ExportToExcelButton nnis={nnis} />
            <SortedNniTable
                nnis={nnis}
            />
        </Container>
    );
}

const ExtractedFromNetworkInfo = () => {
    const info = (
        <div>
            <Typography variant="h6">Information</Typography>
            <Typography variant="body1">
                <p>
                    NNIs are found by querying the network devices for interfaces with a specific description.
                    if they contain :ICO in the description, they are considered NNIs.
                </p>
                <p>
                    Types of NNIs are found by extra info in the description. 
                    <ul>
                        <li>L2: :ICO</li>
                        <li>Option A: :ICO Option A</li>
                        <li>Option B: :ICO Option B</li>
                        <li>Option C: :ICO Option C</li>
                    </ul>
                </p>
                <p>
                    They are filtered on the following criteria in Spectrum:
                    <ul>
                        <li>Operational Status: UP</li>
                        <li>Admin Status: UP</li>
                        <li>Retire Time: null</li>
                        <li>Device Lifecycle State: ACTIVE</li>
                    </ul>
                </p>
            </Typography>
            <Typography variant="h6">Circuits</Typography>
            <Typography variant="body1">
                <p>
                    Circuits are found by querying sub interfaces on the interfaces found to be NNIs.
                </p>
                <p>
                    Types of Circuits are found by extra info in the type field of the description.
                    <ul>
                        <li>L2: L</li>
                        <li>Internet: I</li>
                        <li>IPVPN: V</li>
                    </ul>
                </p>
            </Typography>
        </div>
    )

    const handleClick = () => {
        toast.info(info);
    };

    return (
        <Box mb={5}>
            <Alert severity="info" onClick={handleClick}>as extracted from the network devices</Alert>
        </Box>
    );
}

interface ExcelExportData {
    NNI_Provider: string;
    NNI_Circuit: string;
    NNI_Description: string;
    NNI_Device: string;
    NNI_Interface: string;
    Circuit: string;
    Customer: string;
    Description: string;
    Name: string;
    Speed: string;
    Type: string;
    Traffic: string;
}

const ExportToExcelButton = (props: { nnis: BandWidthInterface[] })  => {
    const { nnis } = props;
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);

    const exportToExcel = async () => {
        setLoading(true);
        const data = [] as ExcelExportData[];
        setProgress(0);
        let i = 0;
        for (const nni of nnis) {
            const subs = await getCircuitsOnNni(nni.device.ID, nni.Name);
            const subdata = subs.map(subInterface => ({
                NNI_Provider: nni.description?.provider || "",
                NNI_Circuit: nni.description?.cid || "",
                NNI_Description: nni.description?.description || "",
                NNI_Device: nni.device?.SysName || "",
                NNI_Interface: nni.Name || "",
                Circuit: subInterface.description?.cid || "",
                Customer: subInterface.description?.provider || "",
                Description: subInterface.description?.description  || "",
                Name: subInterface.Name || "",
                Speed: subInterface.description?.speed || "",
                Type: subInterface.description?.type || "",
                Traffic: humanReadableSpeed(subInterface.portmfs?.results[0]?.im_Bits || 0),
            }));
            data.push(...subdata);
            i++;
            setProgress((i / nnis.length) * 100);
        }
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'SubInterfaces');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

        const date = new Date();
        const timestamp = `${date.getFullYear()}_${(date.getMonth() + 1).toString().padStart(2, '0')}_${(date.getDate() - 1).toString().padStart(2, '0')}_${date.getHours().toString().padStart(2, '0')}_${date.getMinutes().toString().padStart(2, '0')}_${date.getSeconds().toString().padStart(2, '0')}`;
        const filename = `CircuitsOverNNIs-${timestamp}.xlsx`;

        setLoading(false);
        saveAs(blob, filename);
    };

    return (
        <>
            <Button onClick={exportToExcel} variant="contained" color="primary" style={{ marginBottom: '20px' }}>
                Export to Excel
            </Button>
            {loading && (<LinearProgress variant="determinate" value={progress} />)}
        </>
    );
}

type SortedNniTableProps = {
    nnis: BandWidthInterface[];
}
    
const SortedNniTable: React.FC<SortedNniTableProps> = ({ nnis }) => {
    const [subInterfaceCounts, setSubInterfaceCounts] = useState<{ [key: string]: {count: number, l2: number, internet: number, ipvpn: number} }>({});

    const onSubInterfaceCountChange = (nniKey: string, count: number, l2: number, internet: number, ipvpn: number) => {
        setSubInterfaceCounts(prevCounts => ({
            ...prevCounts,
            [nniKey]: { count, l2, internet, ipvpn }
        }));
    }
    
    return (
        <Container>
            <CircuitSummary numNnis={nnis.length} subInterfaceCounts={subInterfaceCounts} />
            <NniSummary nnis={nnis} />
            <NniDetails nnis={nnis} onSubInterfaceCountChange={onSubInterfaceCountChange} />
        </Container>
    );
};

type NniDetailsProps = {
    nnis: BandWidthInterface[];
    onSubInterfaceCountChange: (nniKey: string, count: number, l2: number, internet: number, ipvpn: number) => void;
}

const NniDetails: React.FC<NniDetailsProps> = ({nnis, onSubInterfaceCountChange}) => {
    const [sortCriteria, setSortCriteria] = useState<string>("provider");
    const [sortDirection, setSortDirection] = useState<"asc" | "desc" | undefined>("asc");
    const [sortedNnis, setSortedNnis] = useState<BandWidthInterface[]>([]);
    const [subInterfaceCounts, setSubInterfaceCounts] = useState<{ [key: string]: {count: number, l2: number, internet: number, ipvpn: number} }>({});
    
    useEffect(() => {
        const sortNnis = () => {
            const sorted = [...nnis].sort((a, b) => {
                switch (sortCriteria) {
                    case 'provider':
                        const aProvider = a.description.provider?.toLowerCase() || "";
                        const bProvider = b.description.provider?.toLowerCase() || "";
                        return sortDirection === 'asc' ? aProvider.localeCompare(bProvider) : bProvider.localeCompare(aProvider);
                    case 'device':
                        const aDevice = a.device.SysName?.toLowerCase() || "";
                        const bDevice = b.device.SysName?.toLowerCase() || "";
                        return sortDirection === 'asc' ? aDevice.localeCompare(bDevice) : bDevice.localeCompare(aDevice);
                    case 'traffic':
                        const aTraffic = a.portmfs?.results[0]?.im_Bits || 0;
                        const bTraffic = b.portmfs?.results[0]?.im_Bits || 0;
                        return sortDirection === 'asc' ? aTraffic - bTraffic : bTraffic - aTraffic;
                    case 'circuit':
                        const aCircuit = a.description.cid?.toLowerCase() || "";
                        const bCircuit = b.description.cid?.toLowerCase() || "";
                        return sortDirection === 'asc' ? aCircuit.localeCompare(bCircuit) : bCircuit.localeCompare(aCircuit);
                    case 'circuits':
                        const aCount = subInterfaceCounts[`${a.device.SysName}-${a.Name}`]?.count || 0;
                        const bCount = subInterfaceCounts[`${b.device.SysName}-${b.Name}`]?.count || 0;
                        return sortDirection === 'asc' ? aCount - bCount : bCount - aCount;
                    default:
                        return 0;
                }
            });
            setSortedNnis(sorted);
        };
        sortNnis();
        
    }, [nnis, sortCriteria, sortDirection, subInterfaceCounts]);

    const handleSort = (criteria: string) => {
        const direction = sortCriteria === criteria && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortCriteria(criteria);
        setSortDirection(direction);
    };

    const handleSubInterfaceCountChange = (nniKey: string, count: number, l2: number, internet: number, ipvpn: number) => {
        setSubInterfaceCounts(prevCounts => ({
            ...prevCounts,
            [nniKey]: { count, l2, internet, ipvpn }
        }));
        onSubInterfaceCountChange(nniKey, count, l2, internet, ipvpn);
    }

    return (
        <Box mb={10}>
            <Typography variant="h2">NNI Details</Typography>
            <Table>
            <TableHead>
                <TableRow>
                <TableCell>
                    <TableSortLabel
                    active={sortCriteria === 'provider'}
                    direction={sortDirection}
                    onClick={() => handleSort('provider')}
                    >
                    Provider
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                    active={sortCriteria === 'circuit'}
                    direction={sortDirection}
                    onClick={() => handleSort('circuit')}
                    >
                        Circuit
                    </TableSortLabel>
                </TableCell>
                <TableCell>Description</TableCell>
                <TableCell>
                    <TableSortLabel
                    active={sortCriteria === 'device'}
                    direction={sortDirection}
                    onClick={() => handleSort('device')}
                    >
                    Device
                    </TableSortLabel>
                </TableCell>
                <TableCell>Interface</TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortCriteria === 'traffic'}
                        direction={sortDirection}
                        onClick={() => handleSort('traffic')}
                        >
                        Traffic last 24h (Avg)
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortCriteria === 'circuits'}
                        direction={sortDirection}
                        onClick={() => handleSort('circuits')}
                        >
                        Circuits
                    </TableSortLabel>
                </TableCell>
                <TableCell>Status</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {sortedNnis.map((nni: BandWidthInterface) => (
                <TableRow key={`${nni.Name}-${nni.device.SysName}`}>
                    <TableCell>{nni.description.provider}</TableCell>
                    <TableCell>{nni.description.cid}</TableCell>
                    <TableCell>{nni.description.description}</TableCell>
                    <TableCell>
                    <NniEquipmentSummaryWithPopover nni={nni} />
                    </TableCell>
                    <TableCell>{nni.Name}</TableCell>
                    <TableCell>
                    {humanReadableSpeed(nni.portmfs?.results[0]?.im_Bits || 0)} (
                    {humanReadableAverageSpeed(nni.portmfs?.results[0]?.im_Bits || 0)})
                    </TableCell>
                    <TableCell><CircuitsOnInterfaceSummary nni={nni} nniKey={`${nni.device.SysName}-${nni.Name}`} onSubInterfaceCountChange={handleSubInterfaceCountChange}/></TableCell>
                    <TableCell>
                    <LegacyOperationalStatus
                        interfaceName={nni.Name}
                        mgmtIp={nni.device.PrimaryIPAddress}
                    />
                    </TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
        </Box>
    );
};


const CircuitSummary = (props: { numNnis: number, subInterfaceCounts: { [key: string]: { count: number; l2: number; internet: number; ipvpn: number; } } }) => {
    const { subInterfaceCounts, numNnis } = props;
    const [totalSubInterfaceCount, setTotalSubInterfaceCount] = useState<number>(0);
    const [totalL2SubInterfaceCount, setTotalL2SubInterfaceCount] = useState<number>(0);
    const [totalInternetSubInterfaceCount, setTotalInternetSubInterfaceCount] = useState<number>(0);
    const [totalIpVpnSubInterfaceCount, setTotalIpVpnSubInterfaceCount] = useState<number>(0);
    const [totalUncategorizedSubInterfaceCount, setTotalUncategorizedSubInterfaceCount] = useState<number>(0);

    useEffect(() => {
        const total = Object.values(subInterfaceCounts).reduce((acc, val) => acc + val.count, 0);
        const l2 = Object.values(subInterfaceCounts).reduce((acc, val) => acc + val.l2, 0);
        const internet = Object.values(subInterfaceCounts).reduce((acc, val) => acc + val.internet, 0);
        const ipvpn = Object.values(subInterfaceCounts).reduce((acc, val) => acc + val.ipvpn, 0);
        const uncategorized = total - l2 - internet - ipvpn;

        setTotalSubInterfaceCount(total);
        setTotalL2SubInterfaceCount(l2);
        setTotalInternetSubInterfaceCount(internet);
        setTotalIpVpnSubInterfaceCount(ipvpn);
        setTotalUncategorizedSubInterfaceCount(uncategorized);
    }
    , [subInterfaceCounts]);

    const subInterfaceCountKeys = Object.keys(subInterfaceCounts).length;
    const finishedCount = subInterfaceCountKeys === numNnis && numNnis !== 0;

    return (
        <Box mb={10}>
            <Box display="flex" alignItems="center" mb={5}>
                <Typography variant="h2">Circuit Summary</Typography>
                <div style={{ flexGrow: 1 }}/>
                <PieChartComponent
                    data={[
                        { name: 'L2', value: totalL2SubInterfaceCount },
                        { name: 'Internet', value: totalInternetSubInterfaceCount },
                        { name: 'IPVPN', value: totalIpVpnSubInterfaceCount },
                        { name: 'Uncategorized', value: totalUncategorizedSubInterfaceCount },
                    ]}
                    viewLabel={finishedCount}
                />
            </Box>
            <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Total
                    </TableCell>
                    <TableCell>
                        L2
                    </TableCell>
                    <TableCell>
                        Internet
                    </TableCell>
                    <TableCell>
                        IPVPN
                    </TableCell>
                    <TableCell>
                        Uncategorized
                    </TableCell>
                    <TableCell>
                        Status
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>{totalSubInterfaceCount}</TableCell>
                    <TableCell>{totalL2SubInterfaceCount}</TableCell>
                    <TableCell>{totalInternetSubInterfaceCount}</TableCell>
                    <TableCell>{totalIpVpnSubInterfaceCount}</TableCell>
                    <TableCell>{totalUncategorizedSubInterfaceCount}</TableCell>
                    <TableCell>
                    <Box sx={{ height: 40, width: 200, display: 'flex', alignItems: 'center' }}>
                                {finishedCount ? (
                                    <Alert severity="success" sx={{ width: '100%' }}>All circuits counted</Alert>
                                ) : (
                                    <LinearProgress variant="determinate" value={(subInterfaceCountKeys / numNnis) * 100} sx={{ width: '100%' }} />
                                )}
                            </Box>
                    </TableCell>
                </TableRow>
            </TableBody>
            </Table>
        </Box>
    )
};

const NniSummary = (props: { nnis: BandWidthInterface[] }) => {
    const { nnis } = props;
    const [totalNni, setTotalNni] = useState<number>(0);
    const [totalL2Nni, setTotalL2Nni] = useState<number>(0);
    const [totalANni, setTotalANni] = useState<number>(0);
    const [totalBNni, setTotalBNni] = useState<number>(0);
    const [totalCNni, setTotalCNni] = useState<number>(0);

    const [totalTraffic, setTotalTraffic] = useState<number>(0);
    const [totalTrafficL2, setTotalTrafficL2] = useState<number>(0);
    const [totalTrafficA, setTotalTrafficA] = useState<number>(0);
    const [totalTrafficB, setTotalTrafficB] = useState<number>(0);
    const [totalTrafficC, setTotalTrafficC] = useState<number>(0);

    useEffect(() => {
        const total = nnis.length;
        const a = nnis.filter(nni => nni.Alias.toLowerCase().includes("option a")).length;
        const b = nnis.filter(nni => nni.Alias.toLowerCase().includes("option b")).length;
        const c = nnis.filter(nni => nni.Alias.toLowerCase().includes("option c")).length;
        const l2 = total - a - b - c;

        setTotalNni(total);
        setTotalL2Nni(l2);
        setTotalANni(a);
        setTotalBNni(b);
        setTotalCNni(c);

        const traffic = nnis.reduce((acc, nni) => acc + Number(nni.portmfs?.results[0]?.im_Bits || 0), 0);
        const trafficA = nnis.filter(nni => nni.Alias.toLowerCase().includes("option a")).reduce((acc, nni) => acc + Number(nni.portmfs?.results[0]?.im_Bits || 0), 0);
        const trafficB = nnis.filter(nni => nni.Alias.toLowerCase().includes("option b")).reduce((acc, nni) => acc + Number(nni.portmfs?.results[0]?.im_Bits || 0), 0);
        const trafficC = nnis.filter(nni => nni.Alias.toLowerCase().includes("option c")).reduce((acc, nni) => acc + Number(nni.portmfs?.results[0]?.im_Bits || 0), 0);
        const trafficL2 = traffic - trafficA - trafficB - trafficC;
        setTotalTraffic(traffic);
        setTotalTrafficA(trafficA);
        setTotalTrafficB(trafficB);
        setTotalTrafficC(trafficC);
        setTotalTrafficL2(trafficL2);
    }, [nnis]);
    
    return (
        <Box mb={10}>
            <Box display="flex" alignItems="center" mb={5}>
                <Typography variant="h2">NNI Summary</Typography>
                <div style={{ flexGrow: 1 }}/>
                <PieChartComponent
                    data={[
                        { name: 'L2', value: totalL2Nni },
                        { name: 'Option A', value: totalANni },
                        { name: 'Option B', value: totalBNni },
                        { name: 'Option C', value: totalCNni },
                    ]}
                    viewLabel={true}
                />
            </Box>
            <Table>
            <TableHead>
                <TableRow>
                    <TableCell />
                    <TableCell>
                        Total
                    </TableCell>
                    <TableCell>
                        L2
                    </TableCell>
                    <TableCell>
                        L3 Option A
                    </TableCell>
                    <TableCell>
                        L3 Option B
                    </TableCell>
                    <TableCell>
                        L3 Option C
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>Count</TableCell>
                    <TableCell>{totalNni}</TableCell>
                    <TableCell>{totalL2Nni}</TableCell>
                    <TableCell>{totalANni}</TableCell>
                    <TableCell>{totalBNni}</TableCell>
                    <TableCell>{totalCNni}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Traffic last 24h (Avg)</TableCell>
                    <TableCell>{humanReadableSpeed(totalTraffic)} ({humanReadableAverageSpeed(totalTraffic)})</TableCell>
                    <TableCell>{humanReadableSpeed(totalTrafficL2)} ({humanReadableAverageSpeed(totalTrafficL2)})</TableCell>
                    <TableCell>{humanReadableSpeed(totalTrafficA)} ({humanReadableAverageSpeed(totalTrafficA)})</TableCell>
                    <TableCell>{humanReadableSpeed(totalTrafficB)} ({humanReadableAverageSpeed(totalTrafficB)})</TableCell>
                    <TableCell>{humanReadableSpeed(totalTrafficC)} ({humanReadableAverageSpeed(totalTrafficC)})</TableCell>
                </TableRow>
            </TableBody>
            </Table>
        </Box>
    )
}

const NniEquipmentSummaryWithPopover = (props: { nni: BandWidthInterface} ) => {
    const { nni } = props;
    const [hostId, setHostId] = useState<string>("");
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchHostId = async () => {
            const id = await getHostId(props.nni);
            setHostId(id);
            setLoading(false);
        };
        fetchHostId();
    }, [props.nni]);
    if (loading || !hostId) {
        return <div>{nni.device.SysName}</div>;
    }
    return <EquipmentSummaryWithPopover name={nni.device?.SysName || "Unknown"} id={hostId} />;
}

type CircuitsOnInterfaceSummaryProps = {
    nni: BandWidthInterface;
    nniKey?: string;
    onSubInterfaceCountChange?: (nniKey: string, count: number, l2: number, internet: number, ipvpnv: number) => void;
}


const CircuitsOnInterfaceSummary: React.FC<CircuitsOnInterfaceSummaryProps> = ({nni, nniKey, onSubInterfaceCountChange}) => {
    const [subInterfaces, setSubInterfaces] = useState<BandWidthInterface[]>([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchSubInterfaces = async () => {
            const subInterfaces = await getActiveSubInterfaces({device: {ID: nni.device.ID}, Name: nni.Name} as BandWidthInterface);
            setSubInterfaces(subInterfaces);
            if (onSubInterfaceCountChange) {
                const l2 = subInterfaces.filter(subInterface => subInterface.description.type === "L").length;
                const internet = subInterfaces.filter(subInterface => subInterface.description.type === "I").length;
                const ipvpnv = subInterfaces.filter(subInterface => subInterface.description.type === "V").length;
                onSubInterfaceCountChange(nniKey || `${nni.device?.SysName}-${nni.Name}`, subInterfaces.length, l2, internet, ipvpnv);
            }
            setLoading(false);
        };
        fetchSubInterfaces();
    }, [nni]);
    return (
        <div>
            {loading ? (<Skeleton variant="rectangular" width="3ch" height="1em" />) :
            (<CircuitsOnInterfaceDetailsWithPopover name={`${subInterfaces.length}`} subInterfaces={subInterfaces} subOf={`${nni.description.provider} - ${nni.description.description} on ${nni.device.SysName} : ${nni.Name}`} direct={false}/>)
            }
        </div>
    );
}

type CircuitsOnInterfaceDetailsWithPopoverProps = {
    name: string;
    subInterfaces: BandWidthInterface[];
    subOf: string;
    direct: boolean;
}

const CircuitsOnInterfaceDetailsWithPopover: React.FC<CircuitsOnInterfaceDetailsWithPopoverProps> = ({ name, subInterfaces, subOf, direct }) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [popoverSize, setPopoverSize] = useState({ maxHeight: '80vh', maxWidth: '80vw' });

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (direct) {
            setAnchorEl(document.body); // Set anchorEl to a non-null value to open the popover
        }
    }, [direct]);

    useEffect(() => {
        if (anchorEl) {
            const rect = anchorEl.getBoundingClientRect();
            const viewportHeight = window.innerHeight;
            const viewportWidth = window.innerWidth;

            const availableHeight = viewportHeight - rect.bottom;
            const availableWidth = viewportWidth - rect.left;

            setPopoverSize({
                maxHeight: `${availableHeight}px`,
                maxWidth: '95vw',
            });
        }
    }, [anchorEl]);

    const open = Boolean(anchorEl);
    const popoverId = open ? "simple-popover" : undefined;

    return (
        <>
            {!direct && <Button onClick={handleClick}>{name}</Button>}
            <Popover
                id={popoverId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        maxHeight: popoverSize.maxHeight,
                        maxWidth: popoverSize.maxWidth,
                        overflow: 'auto',
                    },
                }}
            >
                <div
                    style={{
                        padding: theme.spacing(2),
                        border: "1px solid #ccc",
                        backgroundColor: theme.palette.background.paper,
                        overflow: 'auto',
                        boxSizing: 'border-box',
                    }}
                >
                    <CircuitsOnInterfaceDetails header={subOf} subInterfaces={subInterfaces} />
                </div>
            </Popover>
        </>
    );
};

const CircuitsOnInterfaceDetails = (props: {subInterfaces: BandWidthInterface[], header: string}) => {
    const { subInterfaces, header } = props;
    const [sortedSubInterfaces, setSortedSubInterfaces] = useState<BandWidthInterface[]>([]);
    const [sortCriteria, setSortCriteria] = useState<string>("circuit");
    const [sortDirection, setSortDirection] = useState<"asc" | "desc" | undefined>("asc");

    useEffect(() => {

        const sortSubInterfaces = () => {
            const sorted = [...subInterfaces].sort((a, b) => {
                switch (sortCriteria) {
                    case 'circuit':
                        const aProvider = a.description.cid?.toLowerCase() || "";
                        const bProvider = b.description.cid?.toLowerCase() || "";
                        return sortDirection === 'asc' ? aProvider.localeCompare(bProvider) : bProvider.localeCompare(aProvider);
                    case 'traffic':
                        const aTraffic = a.portmfs?.results[0]?.im_Bits || 0;
                        const bTraffic = b.portmfs?.results[0]?.im_Bits || 0;
                        return sortDirection === 'asc' ? aTraffic - bTraffic : bTraffic - aTraffic;
                    case 'customer':
                        const aCustomer = a.description.provider?.toLowerCase() || "";
                        const bCustomer = b.description.provider?.toLowerCase() || "";
                        return sortDirection === 'asc' ? aCustomer.localeCompare(bCustomer) : bCustomer.localeCompare(aCustomer);
                    case 'description':
                        const aDescription = a.description.description?.toLowerCase() || "";
                        const bDescription = b.description.description?.toLowerCase() || "";
                        return sortDirection === 'asc' ? aDescription.localeCompare(bDescription) : bDescription.localeCompare(aDescription);
                    case 'type':
                        const aType = a.description.type?.toLowerCase() || "";
                        const bType = b.description.type?.toLowerCase() || "";
                        return sortDirection === 'asc' ? aType.localeCompare(bType) : bType.localeCompare(aType);
                    case 'speed':
                        const aSpeed = speed2Number(a.description?.speed || "0");
                        const bSpeed = speed2Number(b.description?.speed || "0");
                        return sortDirection === 'asc' ? aSpeed - bSpeed : bSpeed - aSpeed;
                    case 'name':
                        const aName = a.Name?.toLowerCase() || "";
                        const bName = b.Name?.toLowerCase() || "";
                        return sortDirection === 'asc' ? aName.localeCompare(bName) : bName.localeCompare(aName);
                    default:
                        return 0;
                }
            });
            setSortedSubInterfaces(sorted);
        };
        sortSubInterfaces();
    }, [subInterfaces, sortCriteria, sortDirection]);

    const handleSort = (criteria: string) => {
        const direction = sortCriteria === criteria && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortCriteria(criteria);
        setSortDirection(direction);
    };

    return (
        <Container>
            <Typography variant="h6">{header} ({subInterfaces.length})</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={sortCriteria === 'circuit'}
                                direction={sortDirection}
                                onClick={() => handleSort('circuit')}
                            >
                                Circuit
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortCriteria === 'customer'}
                                direction={sortDirection}
                                onClick={() => handleSort('customer')}
                            >
                                Customer
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortCriteria === 'description'}
                                direction={sortDirection}
                                onClick={() => handleSort('description')}
                            >
                                Description
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortCriteria === 'type'}
                                direction={sortDirection}
                                onClick={() => handleSort('type')}
                            >
                                Type
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortCriteria === 'speed'}
                                direction={sortDirection}
                                onClick={() => handleSort('speed')}
                            >
                                Speed
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortCriteria === 'name'}
                                direction={sortDirection}
                                onClick={() => handleSort('name')}
                            >
                                Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortCriteria === 'traffic'}
                                direction={sortDirection}
                                onClick={() => handleSort('traffic')}
                            >
                                Traffic last 24h (AVG)
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedSubInterfaces.map((subInterface: BandWidthInterface) => (
                        <TableRow key={subInterface.Name}>
                            <TableCell>{subInterface.description.cid}</TableCell>
                            <TableCell>{subInterface.description.provider}</TableCell>
                            <TableCell>{subInterface.description.description}</TableCell>
                            <TableCell>{subInterface.description.type}</TableCell>
                            <TableCell>{subInterface.description.speed}</TableCell>
                            <TableCell>{subInterface.Name}</TableCell>
                            <TableCell>{humanReadableSpeed(subInterface.portmfs?.results[0]?.im_Bits || 0)} ({humanReadableAverageSpeed(subInterface.portmfs?.results[0]?.im_Bits || 0)})</TableCell>
                            <TableCell><LegacyOperationalStatus interfaceName={subInterface.Name} mgmtIp={subInterface.device?.PrimaryIPAddress || ""} /></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Container>
    );
}

const getNNIs = async (): Promise<BandWidthInterface[]> => {
    // const filter = "((substringof(tolower(':ICO'), tolower(Alias)) eq true) and (Type ne 135))"; // All interfaces with alias containing ":ICO" and not of type 135 (subinterface)
    const filter = "(substringof(tolower(':ICO'), tolower(Alias)) eq true)"; // All interfaces with alias containing ":ICO" include subinterfaces
    const select = "Name,OperStatus,AdminStatus,SpeedIn,SpeedOut,RetireTime,Alias,device/PrimaryIPAddress,device/SysName,device/ID,device/LifeCycleState,portmfs/im_Bits"; // retiretime signifies this has been retired and should be filtered, portmfs/im_Bits is traffic on the port to see if it is active
    const expand = "device,portmfs" // get device info and traffic stats too
    const top = 1000; // Get top 1000 interfaces
    const skip = 0;
    const format = "json";
    const period = "1d"; // Get traffic data for the last day
    const resolution = "DAY"; // Get only one measurement for the day, only going to use it to see if the interface is active
    try {
        await configureOpenAPI(OpenAPI);
        const response = await getOdata({proxyPath: `interfaces?$filter=${filter}&$select=${select}&$expand=${expand}&$format=${format}&period=${period}&resolution=${resolution}&$top=${top}&$skip=${skip}`}) as BandWidthResponse;
        const nnis = response?.d?.results;
        // remove domain name from hostname
        nnis.forEach((nni: BandWidthInterface) => {
            nni.device.SysName = nni.device.SysName.split(".")[0];
        });
        // split description into fields
        nnis.forEach((nni: BandWidthInterface) => {
            const description = nni.Alias.split(":");
            nni.description = {
                cid: description[0],
                provider: description[1],
                speed: description[2],
                type: description[3],
                description: description[4]
            };
        });
        return nnis;
    }
    catch (error) {
        toast.error("Error fetching NNI data: " + error);
        return [];
    }
}

const filterInactiveInterfaces = (interfaces: BandWidthInterface[]): BandWidthInterface[] => {
    if (!Array.isArray(interfaces)) {
        return [];
    }
    const filteredNNIs = interfaces.filter((i: BandWidthInterface) => {
        // return i.OperStatus === 1 && i.AdminStatus === 1 && i.RetireTime === null && i.portmfs?.results[0]?.im_Bits > 0 && i.device.LifeCycleState == "ACTIVE"; // Filter on traffic
        return i.OperStatus === 1 && i.AdminStatus === 1 && i.RetireTime === null && i.device.LifeCycleState == "ACTIVE"; // Do not filter on traffic

    });
    return filteredNNIs;
}

const deduplicateNNIs = (nnis: BandWidthInterface[]) : BandWidthInterface[] => {
    if (!Array.isArray(nnis)) {
        return [];
    }
    const deduplicatedNNIs = nnis.reduce((acc: BandWidthInterface[], nni: BandWidthInterface) => {
        // deduplicate if name and device system name are the same
        const existingNni = acc.find((existingNni: BandWidthInterface) => {
            return existingNni.Name === nni.Name && existingNni.device.SysName === nni.device.SysName;
        });
        if (!existingNni) {
            acc.push(nni);
        }
        return acc;
    }, []);
    return deduplicatedNNIs;
}

const getActiveNNIs = async () => {
    const nnis = await getNNIs();
    const deduplicatedNNIs = deduplicateNNIs(nnis);
    const activeNNIs = filterInactiveInterfaces(deduplicatedNNIs);
    return activeNNIs;
}

const getHostId = async (nni: BandWidthInterface): Promise<string> => {
    const hostname = nni.device?.SysName ?? "";
    const ipAddress = nni.device?.PrimaryIPAddress ?? "";
    if (!hostname || !ipAddress) {
        console.error("Could not find hostname or IP address for", nni.device.SysName);
        return "";
    }
    const ids = await fetchResourceEquipmentExternalIdsByExternalIds([
    hostname,
    ipAddress,
    ]);
    if (!ids) {
        console.error("Could not find external IDs for", hostname, ipAddress);
        return "";
    }
    for (const id of ids) {
        if (id.system_name === "INVENTORY") {
            return id.external_id || "";
        }
    }
    return "";
}

const getCircuitsOnNni = async (deviceId: string, intName: string): Promise<BandWidthInterface[]> => {
    const filter = `((device/ID eq ${deviceId}) and (startswith(Name, '${intName}') eq true) and (Type eq 135))`; // All interfaces from device with ID where the interfacename starts with the specified interface name (gets all the sub interfaces) accept type 135 only (vlan interfaces)
    const select = "Name,OperStatus,AdminStatus,SpeedIn,SpeedOut,RetireTime,Alias,device/PrimaryIPAddress,device/SysName,device/ID,device/LifeCycleState,portmfs/im_Bits"; // retiretime signifies this has been retired and should be filtered, portmfs/im_Bits is traffic on the port to see if it is active
    const expand = "device,portmfs" // get traffic stats too
    const top = 1000; // Get top 1000 interfaces
    const skip = 0;
    const format = "json";
    const period = "1d"; // Get traffic data for the last day
    const resolution = "DAY"; // Get only one measurement for the day, only going to use it to see if the interface is active
    try {
        await configureOpenAPI(OpenAPI);
        const response = await getOdata({proxyPath: `interfaces?$filter=${filter}&$select=${select}&$expand=${expand}&$format=${format}&period=${period}&resolution=${resolution}&$top=${top}&$skip=${skip}`}) as BandWidthResponse;
        const circuits = response?.d?.results;
        // split description into fields
        circuits.forEach((nni: BandWidthInterface) => {
            const description = nni.Alias.split(":");
            nni.description = {
                cid: description[0],
                provider: description[1],
                speed: description[2],
                type: description[3],
                description: description[4]
            };
        });
        return circuits;
    }
    catch (error) {
        toast.error("Error fetching NNI data: " + error);
        return [];
    }
}

const getActiveSubInterfaces = async (interfaces: BandWidthInterface): Promise<BandWidthInterface[]> => {
    const subInterfaces = await getCircuitsOnNni(interfaces.device.ID, interfaces.Name);
    const activeSubInterfaces = filterInactiveInterfaces(subInterfaces);
    return activeSubInterfaces;
}