import { useState, useEffect, useCallback } from "react";
import { DataImportService } from "../gen/cfsInventoryClient/services.gen";
import { OpenAPI } from "../gen/cfsInventoryClient/core/OpenAPI";
import { configureOpenAPI } from "../utils/openApi";
import { formatDate } from "../utils/formatDate";
import {
  CircularProgress,
  Container,
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  CfGetDataImportErrorsStatisticsResponse,
  CfDataImportErrorRef,
  CfGetDataImportErrorsResponse,
} from "../gen/cfsInventoryClient/types.gen";
import React from "react";
import SearchBox from "../ui-components/SearchBox";

const PAGESIZE = 20;

const isCfGetDataImportErrorsResponse = (
  response: any,
): response is CfGetDataImportErrorsResponse => {
  return response?.rows !== undefined;
};

const isCfGetDataImportErrorsStatisticsResponse = (
  response: any,
): response is CfGetDataImportErrorsStatisticsResponse => {
  return response?.overall !== undefined;
};

const InventoryImport = () => {
  const [reports, setReports] = useState<CfDataImportErrorRef[]>([]);
  const [reportError, setReportError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [searchExtId, setSearchExtId] = useState("");
  const [extId, setExtId] = useState("");
  const [stats, setStats] = useState<CfGetDataImportErrorsStatisticsResponse>();

  const fetchReports = async (reset = false) => {
    setLoading(true);
    try {
      await configureOpenAPI(OpenAPI);
      const response = await DataImportService.dataImportGetDataImportErrors({
        limit: PAGESIZE,
        offset: reset ? 0 : page * PAGESIZE,
        externalId: extId || undefined, // Handle empty extId
      });
      if (isCfGetDataImportErrorsResponse(response)) {
        const newReports = reset
          ? response.rows
          : [...reports, ...response.rows];
        setReports(newReports);
        setHasMore(response.rows.length === PAGESIZE);
        setReportError(""); // Reset error state
      } else {
        setReportError("Invalid response");
        console.error("Invalid response", response);
      }
    } catch (error: any) {
      console.error("Failed to fetch data", error);
      setReportError(error.toString());
    } finally {
      setLoading(false);
    }
  };

  const fetchStats = async () => {
    try {
      await configureOpenAPI(OpenAPI);
      const response =
        await DataImportService.dataImportGetDataImportErrorStatistics();
      if (isCfGetDataImportErrorsStatisticsResponse(response)) {
        setStats(response);
      }
      return null;
    } catch (error: any) {
      console.error("Failed to fetch import statistics", error);
      return null;
    }
  };

  // Fetch stats on first render
  useEffect(() => {
    fetchStats();
  }, []);

  // Fetch more reporst when page changes
  useEffect(() => {
    fetchReports();
  }, [page]);

  // Fetch fresh reports when external ID changes
  useEffect(() => {
    setReports([]); // Clear reports
    setReportError(""); // Reset error state
    fetchReports(true);
  }, [extId]);

  const loadMoreReports = useCallback(async () => {
    if (!loading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [loading, hasMore]);

  const handleScroll = useCallback(() => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    if (
      scrollTop + clientHeight >= scrollHeight - clientHeight &&
      !loading &&
      hasMore
    ) {
      loadMoreReports();
      setLoading(true);
    }
  }, [loading, hasMore, loadMoreReports]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const statsView = () => {
    if (!stats) {
      return null;
    }
    return (
      <div>
        <h2>Statistics</h2>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Total Errors</TableCell>
                <TableCell>Systems</TableCell>
                <TableCell>Object Types</TableCell>
                <TableCell>Earliest</TableCell>
                <TableCell>Latest</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{stats?.overall?.header?.entry_count}</TableCell>
                <TableCell>{stats?.overall?.source_systems}</TableCell>
                <TableCell>{stats?.overall?.object_types}</TableCell>
                <TableCell>
                  {formatDate(stats?.overall?.header?.from ?? "")}
                </TableCell>
                <TableCell>
                  {formatDate(stats?.overall?.header?.to ?? "")}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const searchView = () => {
    return (
      <div>
        <h2>External ID</h2>
        <SearchBox
          searchTerm={searchExtId}
          setSearchTerm={setSearchExtId}
          handleSearch={() => setExtId(searchExtId)}
          placeholder="Fullstendig Ext ID ..."
        />
      </div>
    );
  };

  const dataView = () => {
    if (reportError) {
      return (
        <Alert severity="warning">
          Fail to get inventory import reports: {reportError}
        </Alert>
      );
    }

    if (!loading && reports.length < 1) {
      return <Alert severity="info">Empty inventory import log.</Alert>;
    }
    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>Source System</TableCell>
                <TableCell>Object</TableCell>
                <TableCell>External ID</TableCell>
                <TableCell>CRM ID</TableCell>
                <TableCell>Error Code</TableCell>
                <TableCell>Error</TableCell>
                <TableCell>Additional Info</TableCell>
                <TableCell>Reported Time</TableCell>
                <TableCell>Cleared Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reports.map((report, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {report.cleared_time ? (
                        <Alert severity="success" />
                      ) : (
                        <Alert severity="error" />
                      )}
                    </TableCell>
                    <TableCell>{report.source_system}</TableCell>
                    <TableCell>{report.object}</TableCell>
                    <TableCell>{report.external_id}</TableCell>
                    <TableCell>{report.crm_id}</TableCell>
                    <TableCell>{report.error_code}</TableCell>
                    <TableCell>{report.error}</TableCell>
                    <TableCell>{report.additional_info}</TableCell>
                    <TableCell>
                      {report.open_time ? formatDate(report.open_time) : null}
                    </TableCell>
                    <TableCell>
                      {report.cleared_time
                        ? formatDate(report.cleared_time)
                        : null}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {loading && <CircularProgress />}
      </>
    );
  };

  return (
    <Container maxWidth="xl">
      <div>
        <h1>Inventory Reports</h1>
        {statsView()}
        {searchView()}
        {dataView()}
      </div>
    </Container>
  );
};

export default InventoryImport;
